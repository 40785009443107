<template>
  <div class="containerx">
    <v-row>
      <v-col cols="12">
        <div v-if="pageLoading" class="d-flex flex-column flex-grow-1">
          <SkeletonPreloader type="image" :count="4" classes="pa-1" />
        </div>
        <template v-else>
          <div
            class="d-flex flex-row flex-grow-1 justify-space-between card flex-wrap"
          >
            <div class="d-flex flex-column flex-grow-1 pa-1">
              <v-card class=" pa-5 box-shadow-light" color="#0cdd14">
                <div class="d-flex flex-column flex-grow-1">
                  <h3
                    class="white--text font-weight-bold font-size-lg text-uppercase font"
                  >
                    Completed Fuel Orders
                  </h3>
                  <p class="white--text font font-weight-medium font-size-sm">
                    Overview of completed fuel orders
                  </p>
                </div>
                <span
                  class="white--text font font-weight-bold"
                  style="font-size: 30px;"
                >
                  {{ totalOrders.totalCompletedProductOrders }}</span
                >
              </v-card>
            </div>
            <div class="d-flex flex-column flex-grow-1 pa-1">
              <v-card class=" pa-5 box-shadow-light" color="warning">
                <div class="d-flex flex-column flex-grow-1">
                  <h3
                    class=" font-weight-bold white--text font-size-lg text-uppercase font"
                  >
                    Pending Fuel Orders
                  </h3>
                  <p class=" font white--text font-weight-medium font-size-sm">
                    Overview of pending fuel orders
                  </p>
                </div>
                <span
                  class="white--text font font-weight-bold"
                  style="font-size: 30px;"
                >
                  {{ totalOrders.totalPendingProductOrders }}</span
                >
              </v-card>
            </div>
            <div class="d-flex flex-column flex-grow-1 pa-1">
              <v-card class=" pa-5 box-shadow-light" color="#0cdd14">
                <div class="d-flex flex-column flex-grow-1">
                  <h3
                    class="white--text font-weight-bold font-size-lg text-uppercase font"
                  >
                    Completed Lube Orders
                  </h3>
                  <p class="white--text font font-weight-medium font-size-sm">
                    Overview of completed lube orders
                  </p>
                </div>
                <span
                  class="white--text font font-weight-bold"
                  style="font-size: 30px;"
                >
                  {{ totalOrders.totalCompletedLubeOrders }}</span
                >
              </v-card>
            </div>
            <div class="d-flex flex-column flex-grow-1 pa-1">
              <v-card class=" pa-5 box-shadow-light" color="warning">
                <div class="d-flex flex-column flex-grow-1">
                  <h3
                    class="white--text font-weight-bold font-size-lg text-uppercase font"
                  >
                    Pending Lube Orders
                  </h3>
                  <p class="white--text font font-weight-medium font-size-sm">
                    Overview of pending lube orders
                  </p>
                </div>
                <span
                  class="white--text font font-weight-bold"
                  style="font-size: 30px;"
                >
                  {{ totalOrders.totalPendingLubeOrders }}</span
                >
              </v-card>
            </div>
          </div>
          <div class="d-flex flex-row flex-grow-1 pt-2">
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex flex-column flex-grow-1">
                <h3
                  class=" font font-weight-medium text-uppercase font-size-md"
                >
                  Order Quantity Variations For Fuels Over Period
                </h3>
                <p class=" font font-weight-medium font-size-sm">
                  Overview of the variations of the quantity of orders made over
                  a period for fuels
                </p>
              </div>
              <div class="d-flex flex-row flex-grow-1 ma-1">
                <apexcharts
                  ref="orderVariationGraph"
                  class="d-flex box-shadow-light"
                  height="500"
                  style="width: 100%"
                  :options="chartOptions"
                  :series="series"
                ></apexcharts>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row flex-grow-1 mt-2 flex-wrap">
            <div class="d-flex flex-column flex-grow-1">
              <h3 class=" font-weight-medium font-size-md text-uppercase font">
                List Of All Lube Orders
              </h3>
              <p class=" font font-weight-medium font-size-sm">
                Overview of all lube orders list
              </p>

              <v-data-table
                class=" box-shadow-light mx-1 font font-size-md cursor-pointer px-2 pb-2  mt-1"
                :items="lubeOrderList"
                style="width: 100%;"
                :loading="listLoading"
                @click:row="showDeliveries"
                :headers="headers"
                hide-default-footer
              >
                <template #item.netAmount="{item}">
                  <v-chip label small color="primary">
                    <span class=" font font-weight-medium font-size-sm">{{
                      item.netAmount
                        | currencyFormat(
                          currency.length > 0 ? currency[0].code : "GHS"
                        )
                    }}</span>
                  </v-chip>
                </template>
                <template #item.isOrderApproved="{item}">
                  <v-chip
                    label
                    :color="
                      item.isOrderApproved ? '#9efb9e66' : 'rgb(247, 189, 172)'
                    "
                    class="ft font-weight-medium mr-2 text-uppercase"
                    small
                  >
                    <span
                      :style="{
                        color: item.isOrderApproved
                          ? 'rgba(3, 91, 3, 0.83)'
                          : 'rgb(238, 78, 0)'
                      }"
                      class="font-weight-bold"
                      >Approved</span
                    >
                  </v-chip>
                </template>
                <template #item.isOrderDelivered="{item}">
                  <v-chip
                    label
                    :color="
                      item.isOrderDelivered ? '#9efb9e66' : 'rgb(247, 189, 172)'
                    "
                    class="ft font-weight-medium mr-2 text-uppercase"
                    small
                  >
                    <span
                      :style="{
                        color: item.isOrderDelivered
                          ? 'rgba(3, 91, 3, 0.83)'
                          : 'rgb(238, 78, 0)'
                      }"
                      class="font-weight-bold"
                      >{{
                        item.isOrderDelivered ? "Delivered" : "Not Delivered"
                      }}</span
                    >
                  </v-chip>
                </template>
                <template #item.actions="{item}">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        fab
                        :loading="listLoading"
                        :disabled="listLoading || !item.isOrderDelivered"
                        small
                        :color="
                          selectedLubeOrderId &&
                          selectedLubeOrderId.id === item.id
                            ? 'primary'
                            : ''
                        "
                        v-on="on"
                        @click.stop="showDeliveries(item)"
                      >
                        <i class="material-icons-outlined font-size-sm"
                          >airplay</i
                        >
                      </v-btn>
                    </template>
                    <span class=" font font-weight-medium font-size-sm"
                      >View PPD Supplies & Cost</span
                    >
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </div>
          <div class="d-flex flex-column flex-grow-1 mt-3">
            <template v-if="selectedLubeOrderId">
              <h3 class=" font-weight-medium font-size-md text-uppercase font">
                PPD Supply -
                {{
                  selectedLubeOrderId
                    ? `${selectedLubeOrderId.date} / ${selectedLubeOrderId.vehicleRegistrationNumber} / ${selectedLubeOrderId.orderNumber}`
                    : ""
                }}
              </h3>
              <p class=" font font-weight-medium font-size-sm">
                Overview of PPD Supplies Per Order
              </p>
              <div class="d-flex flex-row flex-grow-1 ma-1">
                <apexcharts
                  class="d-flex box-shadow-light"
                  style="width: 100%"
                  height="400"
                  :options="{
                    ...lubeChartOptions,
                    labels: lubeOrderDeliveries.labels
                  }"
                  :series="lubeOrderDeliveries.data"
                ></apexcharts>
              </div>
            </template>
            <EmptyPage
              v-else
              :image="require(`@/assets/documents.svg`)"
              class="mt-4"
              title="PPD Supply details will show up here."
              subTitle="Click on a lube order to view PPD Supply details"
            />
          </div>
          <div class="d-flex flex-column flex-grow-1 pt-4">
            <h3 class=" font font-weight-medium text-uppercase font-size-md">
              Total Orders Of Lubes Over Period
            </h3>
            <p class=" font font-weight-medium font-size-sm">
              Overview of the total orders made for lubes over period
            </p>
          </div>
          <div class="d-flex flex-row flex-grow-1 ma-1">
            <apexcharts
              ref="orderVariationGraph"
              class="d-flex box-shadow-light"
              style="width: 100%"
              height="500"
              :options="lubeChartOptions"
              :series="lubeOrderSeries"
            ></apexcharts>
          </div>
          <div class="d-flex flex-row flex-grow-1 pt-2">
            <v-chip-group v-model="yearly" show-arrows>
              <v-chip
                v-for="(year, index) in years"
                :key="index"
                small
                label
                mandatory
                active-class="primary"
                color="white"
                class=" font font-weight-bold box-shadow-light"
                >{{ year.year }}</v-chip
              >
            </v-chip-group>
          </div>
          <div class="d-flex flex-row flex-grow-1 justify-space-between mt-1">
            <div class="d-flex flex-column flex-grow-1">
              <h3 class=" font font-weight-medium text-uppercase font-size-md">
                Yearly overview of Fuel Orders
              </h3>
              <span class="font-size-sm font font-weight-medium pb-2"
                >Total orders made within the year for lubes</span
              >
              <div
                class="d-flex flex-row flex-grow-1 flex-wrap my-1 pa-1 box-shadow-light"
              >
                <v-tooltip
                  v-for="(yearlyOrder, index) in fuelYearlyOrders"
                  :key="index"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      class="box"
                      :style="{
                        backgroundColor: `${heatColors(
                          yearlyOrder.totalCount,
                          colors
                        )}`
                      }"
                      style="width: 12px;height:12px;margin:1px"
                    ></div>
                  </template>
                  <span class=" font font-weight-medium font-size-sm"
                    >{{ yearlyOrder.totalCount }} orders made on
                    {{ yearlyOrder.date | timestamp("Do MMMM, YYYY") }}</span
                  >
                </v-tooltip>
                <div class="d-flex flex-row flex-grow-1 justify-end pr-1 pt-2">
                  <span class="mx-2 mt-n1 font font-weight-medium font-size-sm"
                    >More</span
                  >
                  <div
                    v-for="(color, index) in colors"
                    :key="index"
                    class="box"
                    :style="{
                      backgroundColor: `${color}`
                    }"
                    style="width: 12px;height:12px;margin:1px"
                  ></div>
                  <span class="mx-2 mt-n1 font font-weight-medium font-size-sm"
                    >Less</span
                  >
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <h3 class=" font font-weight-medium text-uppercase font-size-md">
                Yearly overview of Lube Orders
              </h3>
              <span class="font-size-sm font font-weight-medium pb-2"
                >Total orders made within the year for lubes</span
              >
              <div
                class="d-flex flex-row flex-grow-1 flex-wrap my-1 box-shadow-light pa-1"
              >
                <v-tooltip
                  v-for="(yearlyOrder, index) in lubeYearlyOrders"
                  :key="index"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      class="box"
                      :style="{
                        backgroundColor: `${heatColors(
                          yearlyOrder.totalCount,
                          colors
                        )}`
                      }"
                      style="width: 12px;height:12px;margin:1px"
                    ></div>
                  </template>
                  <span class=" font font-weight-medium font-size-sm"
                    >{{ yearlyOrder.totalCount }} orders made on
                    {{ yearlyOrder.date | timestamp("Do MMMM, YYYY") }}</span
                  >
                </v-tooltip>
                <div class="d-flex flex-row flex-grow-1 justify-end pr-1 pt-2">
                  <span class="mx-2 mt-n1 font font-weight-medium font-size-sm"
                    >More</span
                  >
                  <div
                    v-for="(color, index) in colors"
                    :key="index"
                    class="box"
                    :style="{
                      backgroundColor: `${color}`
                    }"
                    style="width: 12px;height:12px;margin:1px"
                  ></div>
                  <span class="mx-2 mt-n1 font font-weight-medium font-size-sm"
                    >Less</span
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { currencyFormat } from "../../filters/currencyFormat";
import { timestamp } from "../../filters/timestamp";
import { debounce } from "lodash";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import moment from "moment";
import { from } from "rxjs";
import { groupBy, mergeMap, reduce, toArray } from "rxjs/operators";
export default {
  name: "LubeOrdersOverview",
  props: {
    currency: {
      type: Array,
      default: () => []
    },
    period: {
      type: Object,
      default: null
    },
    range: {
      type: Array,
      default: () => []
    },
    years: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SkeletonPreloader: () => import(`../../components/SkeletonPreloader`),
    SnackBar: () => import(`../../components/SnackBar.vue`),
    EmptyPage: () => import(`../../components/EmptyPage`)
  },
  data: () => ({
    yearly: 0,
    daysInYear: [],
    fuelYearlyOrders: [],
    lubeYearlyOrders: [],
    orderVariationChart: null,
    headers: [
      { text: "Invoice No.", value: "invoiceNumber" },
      { text: "Order No.", value: "orderNumber" },
      { text: "Net Amount", value: "netAmount" },
      { text: "Order Approved", value: "isOrderApproved" },
      { text: "Order Delivered", value: "isOrderDelivered" },
      { text: "Actions", value: "actions" }
    ],
    colors: [
      "rgb(0, 179, 6)",
      "rgba(11, 213, 18, 0.88)",
      "rgba(11, 213, 18, 0.6)",
      "rgba(11, 213, 18, 0.35)"
    ],
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar"
      },
      colors: ["#37474F", "#1B5E20", "#0097A7"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          endingShape: "rounded"
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: [],
      yaxis: [
        {
          title: {
            text: "Quantity"
          }
        }
      ]
    },
    lubeOrderSeries: [],
    lubeChartOptions: {},
    selectedLubeOrderId: null,
    dateRange: []
  }),
  filters: {
    currencyFormat,
    timestamp
  },
  computed: {
    ...mapGetters({
      pageLoading: "getIsPageLoading",
      subLoading: "getIsSubLoading",
      listLoading: "getIsListLoading",
      totalOrders: "reports/getTotalOrders",
      yearlyOrders: "reports/getAllYearlyOrders",
      orderVariationsPerProduct: "reports/getOrderVariationsPerProduct",
      lubeOrdersOverPeriod: "reports/getLubeOrdersOverPeriod",
      lubeOrderList: "reports/getLubeOrderList",
      lubeOrderDeliveries: "reports/getLubeOrderDeliveries"
    })
  },
  mixins: [SnackBarMixins],
  watch: {
    period: debounce(function(date) {
      console.log({ period: date });
      if (date !== null) {
        this.orderOverviewRequest(date, null);
      }
      if (this.dateRange && this.dateRange.length === 2 && !date) {
        this.orderOverviewRequest(null, this.dateRange);
      }
    }, 400),
    yearly(value) {
      this.$store.dispatch("reports/yearlyOrders", { year: value });
    },
    yearlyOrders(payload) {
      const { fuels, lubes } = payload;
      this.fuelYearlyOrders = this.daysInYear.map(date => {
        const fuel = fuels.find(
          order => moment(order.date).format("YYYY-MM-DD") === date
        );
        return {
          date: moment(date).format("YYYY-MM-DD"),
          ...(fuel ? { ...fuel } : { totalCount: 0 })
        };
      });
      this.lubeYearlyOrders = this.daysInYear.map(date => {
        const lube = lubes.find(
          order => moment(order.date).format("YYYY-MM-DD") === date
        );
        return {
          date: moment(date).format("YYYY-MM-DD"),
          ...(lube ? { ...lube } : { totalCount: 0 })
        };
      });
    },
    range(dates) {
      this.selectedLubeOrderId = null;
      const [startDate, endDate] = dates;
      if (dates.length > 1 && moment(startDate).isAfter(moment(endDate))) {
        this.$store.dispatch(
          "showSnackBar",
          {
            message: `Invalid date range provided`,
            status: "red",
            snackBar: true
          },
          { root: true }
        );
        return;
      }
      this.dateRange = dates;
    },
    orderVariationsPerProduct(orders) {
      this.chartOptions = {
        ...this.chartOptions,
        labels: [...new Set([...orders.map(order => order.date)])]
      };
      from(orders)
        .pipe(
          groupBy(order => order.productName),
          mergeMap(group =>
            group.pipe(
              reduce(
                (acc, cur) => {
                  acc.data.push(cur.totalQuantity);
                  return acc;
                },
                { name: group.key, data: [] }
              )
            )
          ),
          toArray()
        )
        .subscribe(data => {
          this.series = data;
        });
    },
    lubeOrdersOverPeriod(orders) {
      this.lubeChartOptions = {
        ...this.chartOptions,
        chart: {
          type: "area",
          height: 350
        },
        stroke: {
          curve: "smooth"
        },
        yaxis: [
          {
            min: 0,
            title: {
              text: "Lubes Order Quantity"
            }
          }
        ],
        labels: [...new Set([...orders.map(order => order.date)])]
      };
      this.lubeOrderSeries = [
        {
          name: "Lubes Order Quantity",
          data: orders.map(order => order.totalCount)
        }
      ];
    }
  },
  methods: {
    heatColors(value, colors) {
      if (Number(value) > 10) {
        return colors[0];
      } else if (Number(value) > 5 && Number(value) <= 10) {
        return colors[1];
      } else if (Number(value) > 3 && Number(value) <= 5) {
        return colors[2];
      } else if (Number(value) > 0 && Number(value) <= 3) {
        return colors[3];
      } else return "#e1e1e8";
    },
    showDeliveries: debounce(function(item) {
      const {
        id,
        date,
        invoiceNumber,
        orderNumber,
        vehicleRegistrationNumber
      } = item;

      this.$store.dispatch("reports/lubeDeliveriesByOrderID", id);
      this.selectedLubeOrderId = {
        id,
        date,
        invoiceNumber,
        orderNumber,
        vehicleRegistrationNumber
      };
    }, 500),
    orderOverviewRequest(date, range) {
      console.log({ range, date });
      this.$store.dispatch("reports/totalOrdersForLubesAndFuels", {
        ...(date
          ? {
              ...date
            }
          : {
              query: `?hasRange=true&startDate=${range[0]}&endDate=${range[1]}`,
              number: 0,
              type: "custom"
            })
      });
      this.$store.dispatch(
        "reports/orderQuantityVariationsPerProductOverPeriod",
        {
          ...(date
            ? {
                ...date
              }
            : {
                query: `?hasRange=true&startDate=${range[0]}&endDate=${range[1]}`,
                number: 0,
                type: "custom"
              })
        }
      );
      this.$store.dispatch("reports/lubeOrderList", {
        ...(date
          ? {
              ...date
            }
          : {
              query: `?hasRange=true&startDate=${range[0]}&endDate=${range[1]}`,
              number: 0,
              type: "custom"
            })
      });
    }
  },
  created() {
    this.daysInYear = new Array(366).fill(0).map((item, index) =>
      moment(`${new Date().getFullYear()}-01-01`)
        .add(index, "day")
        .format("YYYY-MM-DD")
    );
    this.$store.dispatch("reports/yearlyOrders", {
      year: new Date().getFullYear()
    });
    this.$store.dispatch("reports/totalOrdersForLubesAndFuels", {
      ...this.period
    });
    this.$store.dispatch(
      "reports/orderQuantityVariationsPerProductOverPeriod",
      {
        ...this.period
      }
    );
    this.$store.dispatch("reports/lubeOrderList", {
      ...this.period
    });
  }
};
</script>
